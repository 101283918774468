@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,100,500,600,700,800,900);

body {
  margin: 0;
  height: 100vh;
  /* overflow: hidden; to avoid double scrollbars */
}

html, body, #root {
  height: 100%
}

a:hover {
  color: blue;
}

.App {
  height: 100%;
}

.Flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditInput {
    background-color: transparent;
    border: 0;
    padding: 8px;

    &.input:hover {
      background-color: #d3d3d3;
      cursor: pointer;
    }
}

*:focus {
  outline: 0;
}

.Link-Text {
  color: blue;
}

.Form {
  margin-bottom: 30px;
  width: 100%;
}

.Preview-Line-Height {
  line-height: 200%;
}

.Line-Height {
  line-height: 150%;
}

.Line-Height-2 {
  line-height: 2;
}

.Main {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 600px;
  padding-top: 60px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow: scroll;
  background-color: #ebebeb;
  position: absolute;
  top: 0;
}

.Preview {
  background-color: #fff
}

.ResponsiveImageItem {
  height: 200px;
}

.text-select {
  user-select: none;
}

.Submit {
  border-radius: 0px;
  background-color: #1f8bd2;
  color: #fff;
  width: 100px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
}

.TextAlignCenter {
  text-align: center;
}

.WordWrap {
  word-wrap: break-word;
}

.PreWrap {
  white-space: pre-wrap;
}

.WordWrapAll {
  word-break: break-all;
}

.Height50 {
  height: 50px;
}

input {
  background-color: #fafafa;
  border-radius: 20px;
  border-color: gray;
}

.TextArea {
  width: 97%;
  border-radius: 20px;
  border: solid 1px #c7c7c7;
  background: #fafafa;
  padding: 14px 15px;
  color: #231f20;
  display: block;
  margin: 8px 0;
  text-align: left;
  font-size: 16px;
  line-height: 100%;
  box-shadow: none;
  height: 200px;
}

.Font {
   font-family: 'Raleway', sans-serif;
   color: #231f20;
}

.Font-Size-16 {
   font-size: 16px;
}

.Select {
  width: 100%;
  margin-top: 20px;
}

.Error-Container {
  border: 1px solid;
  color: red;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.Input {
  border-radius: 20px;
  border: solid 1px #c7c7c7;
  background: #fafafa;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #231f20;
  display: block;
  text-align: left;
  font-size: 16px;
  line-height: 100%;
  box-shadow: none;
  padding-left: 10px;
}

.Full-Width {
  width: 100%;
}

.Content {
  box-sizing: border-box;
  padding: 25px 8% 40px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.35);
}

.PreviewContent {
  box-shadow: none;
}

.PreviewSubmitContainer {
  position: fixed;
  top: 0px;
  width: 1170px;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  z-index: 1000
}

@media (min-width: 768px) {
  .Content {
    width: 750px;
    padding: 25px 8% 40px;
  }

  .PreviewSubmitContainer {
    width: 750px;
  }

  .PreviewContent {
    width: 850px;
  }
  
  .ResponsiveImage {
    width: 180px;
  }
  .FullWidthResponsiveImage {
    width: 250px;
  }
  .Select-Dropdown {
    width: 95%;
  }
  .ShoutoutContainer {
    height: 200px;
  }
  .ReferralContainer {
    height: 200px;
  }
}

@media (max-width: 992px) {
  .ResponsiveImageItem {
    height: 150px;
  }
}


@media (min-width: 992px) {
  .Content {
    width: 970px;
  }
  .PreviewSubmitContainer {
    width: 970px;
  }
  .PreviewContent {
    width: 1070px;
  }
  .ResponsiveImage {
    width: 250px;
  }
  .FullWidthResponsiveImage {
    width: 250px;
  }

}

@media (min-width: 1200px) {
  .Content {
    width: 1170px; 
  }
  .PreviewSubmitContainer {
    width: 1170px;
  } 

.PreviewContent {
  width: 1270px;
}
.Select-Dropdown {
  width: 100%;
}
.CheckContainer{
  width: 40%;
}


}

@media (max-width: 768px) {
  .Content {
    width: 500px;
    padding: 25px 8% 40px;
  }

  .PreviewSubmitContainer {
    width: 500px;
  }

  .PreviewContent {
    width: 600px;
    padding: 25px 8% 40px;
  }

  .ResponsiveImageItem {
    height: 100px;
  }

  .ResponsiveImage {
    width: 100px;
  }
  .FullWidthResponsiveImage {
    width: 250px;
  }
}

/*@media (max-width: 600px) {
  .ResponsiveImage {
    width: 250px;
  }
  .FullWidthResponsiveImage {
    width: 250px;
  }
}*/

@media (max-width: 500px) {
  .Content {
      width: 95%;
  }
  .PreviewSubmitContainer {
    width: 95%;
  }
  .ResponsiveImage {
    width: 250px;
  }
  .FullWidthResponsiveImage {
    width: 250px;
  }
  .Select-Dropdown {
    width: 95%;
  }
  .CheckContainer{
    width: 100%;
  }
  .ShoutoutContainer {
    height: 400px;
  }
  .ReferralContainer {
      height: 400px;
  }
}


/*@media (min-width: 1200px) {
  .Content {
    width: 1170px;
  }
}*/


.Header {
  font-size: 40px;
  font-weight: 300;
  color: #565152;
  margin: 10px 0 35px;
}



.ContentStyle {
  font-size: 16px;
  margin-bottom: 20px;
}

.PreviewContentStyle {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: 'Times New Roman', 'sans-serif';
}

.Back:hover{
   background-color: #c3c3c3;
    color: #1f8bd2;
}

.Next:hover {
  background-color: #177eb8;
  color: #fff;
}

@media (max-width: 991px) {
  .ContentStyle  {
    font-size: 14px;
  }
  .Header {
    font-size: 32px;
  }
  .FormButton {
    font-size: 16px;
  }
}


.Text {
  margin-bottom: 20px;
  
}

.Left-Align {
  text-align: left;
}

.Bold {
  font-weight: bold;
}

.SubmitButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.FormButton {
   border: 1px solid;
   width: 110px;
   height: 60px;
   margin-right: 10px;
   font-size: 18px;
   font-weight: 400;
   color: #1f8bd2;
   background-color: #dadada;
   font-family: 'Raleway', 'sans-serif';
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
}

#root {
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .FormButton {
      font-size: 16px;
      width: 95px;
      height: 45px;
  }

  .LargeFormButton {
      width: 150px;
  }
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}*/

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

input[type=text], textarea {
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  border: 1px solid #DDDDDD;
}
 
input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px rgb(31, 139, 210);
  border: 1px solid rgb(31, 139, 210);
}

.EditableText:hover {
  border:1px solid #6a82fb;
}

[contenteditable="true"] div {
  font-family: 'Times New Roman', 'sans-serif' !important;
  font-size: 18px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.ShoutoutContainer {
  display: flex;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ReferralContainer {
  display: flex;
  border: 1px solid #d9d9d9;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  border-radius: 15px;
}

/* index.css */ 
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.popup-overlay {
  overflow-y: scroll;
}

.admin-input {
  height: 30px;
  width: 200px;
}
